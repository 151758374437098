export class FR<T> {
	private fr: T;

	private constructor( x: T ) {
		this.fr = x;
	}

	public static of<T>( x: T ): FR<T> {
		return new FR( x );
	}

	public value(): T {
		return this.fr;
	}
}

export class EN<T> {
	private en: T;

	private constructor( x: T ) {
		this.en = x;
	}

	public static of<T>( x: T ): EN<T> {
		return new EN( x );
	}

	public value(): T {
		return this.en;
	}
}

export class NL<T> {
	private nl: T;

	private constructor( x: T ) {
		this.nl = x;
	}

	public static of<T>( x: T ): NL<T> {
		return new NL( x );
	}

	public value(): T {
		return this.nl;
	}
}

export function translate<T>( en: EN<T>, nl?: NL<T>, fr?: FR<T> ): T {
	switch ( document.documentElement.lang ) {
		case 'nl':
			return nl?.value() ?? en.value();
		case 'fr':
			return fr?.value() ?? en.value();
		default:
			return en.value();
	}
}

export interface EnsemblesTranslation {
	locale: string
}

export function translateEnsemblesField<T extends EnsemblesTranslation>( x: Array<T> ): T|null {
	if ( !x.length ) {
		return null;
	}

	const directTranslation = x.find( ( item ) => {
		return item.locale === document.documentElement.lang;
	} );

	if ( directTranslation ) {
		return directTranslation;
	}

	const enFallback = x.find( ( item ) => {
		return item.locale === 'en';
	} );

	if ( enFallback ) {
		return enFallback;
	}

	return x[0];
}
