/* eslint-disable no-lone-blocks */

export function renderTeaserZ( imgSrc: string | null, titleTextContent: string, actorId: string, modalId: string ): HTMLElement {
	// Container
	const outer = document.createElement( 'div' );
	outer.classList.add( 'teaser-z' );

	// Image
	let img: HTMLElement;
	{
		if ( imgSrc ) {
			img = document.createElement( 'img' );
			img.setAttribute( 'width', '200' );
			img.setAttribute( 'height', '200' );
			img.setAttribute( 'src', imgSrc );
			img.setAttribute( 'alt', '' );
		} else {
			img = document.createElement( 'div' );
		}
		img.classList.add( 'teaser-z__image' );
	}

	// Text/Info container
	const info_container = document.createElement( 'div' );
	info_container.classList.add( 'teaser-z__info' );

	// Title
	const title = document.createElement( 'span' );
	title.classList.add( 'teaser-z__image__info__title' );
	title.textContent = titleTextContent;

	// Meta
	const meta = document.createElement( 'span' );
	meta.classList.add( 'teaser-z__image__info__meta' );

	// MrInputSink
	const mrInputSink = document.createElement( 'mr-input-sink' );
	mrInputSink.setAttribute( 'data-for', modalId );
	mrInputSink.setAttribute( 'data-directive', `open:${actorId}` );

	// Button
	const button = document.createElement( 'button' );
	button.textContent = 'info';
	button.setAttribute( 'aria-label', 'Open modal' );

	// structure
	{
		outer.appendChild( img );
		outer.appendChild( info_container );
		info_container.appendChild( title );
		info_container.appendChild( meta );
		meta.appendChild( mrInputSink );
		mrInputSink.appendChild( button );
	}

	return outer;
}
