export async function fetchActorData( actorId: string | null ): Promise<EnsemblesActorResponse | null> {
	if ( !actorId ) {
		return null;
	}

	try {
		// https://ensembles.org/actors/52.json
		const response = await fetch( `https://ensembles.org/actors/${encodeURIComponent( actorId )}.json` );
		if ( !response.ok ) {
			console.warn( 'Could not fetch event data', response );

			return null;
		}

		const data = await response.json() as EnsemblesActorResponse;
		if ( data && data.cached_name ) {
			data.name = data.cached_name;
		}

		return data;
	} catch ( e ) {
		console.warn( 'Could not fetch event data', e );

		return null;
	}
}

export interface EnsemblesActorResponse {
	id: number
	name: string
	email: string
	language_id: number
	permalink: string
	deleted: boolean
	url_1: string
	category_id: number
	date_of_birth: string
	cached_privileges_list: string
	cached_tag_list: string
	publishing_process_id: number
	can_log_in: boolean
	firstname: string
	lastname: string
	annotation: string
	url_2: string
	url_3: string
	cached_name: string
	date_of_death?: string
	cached_name_asc: string
	stream_count_app: number
	description_ca?: string
	short_description_ca?: string
	description_it?: string
	short_description_it?: string
	hide_from_json: boolean
	description_uk?: string
	short_description_uk?: string
	poster_image?: string
	poster_credits?: string
	media_count: number
	items_count: number
	translations: EnsemblesTranslation[]
	locations: EnsemblesLocation[]
}

export interface EnsemblesTranslation {
	locale: string
	short_description: string
	description: string
}

export interface EnsemblesLocation {
	country: string
	place: string
	category: EnsemblesCategory
}

export interface EnsemblesCategory {
	en: string
	nl?: string
	fr?: string
}
