import { Menubutton } from './mr-menu-button';

export function initMenuButtons(): void {
	const handledButtons: WeakMap<Element, true> = new WeakMap();

	function _initMenuButtons() {
		document.querySelectorAll( '[mr-menu-button]' ).forEach( ( el ) => {
			if ( handledButtons.has( el ) ) {
				return;
			}

			if ( el instanceof HTMLElement ) {
				const menuButton = new Menubutton( el );
				menuButton.init();
				handledButtons.set( el, true );
			}
		} );
	}

	_initMenuButtons();
	document.addEventListener( 'readystatechange', _initMenuButtons );
}
