import { MrModalDialog } from '@mrhenry/wp--mr-interactive';
import { EnsemblesActorResponse, fetchActorData } from './actor';
import { renderActorModalDialog } from '../../templates/actor-modal-dialog';
import { EnsemblesActorItemsResponse, fetchActorItemsData } from './actor-items';

export class MrEnsemblesActorModalDialog extends MrModalDialog {
	#actorData: EnsemblesActorResponse | null = null;

	#actorItemsData: EnsemblesActorItemsResponse | null = null;

	#loadingIdempotency: string = crypto.randomUUID();

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override async updateState( directive: string ): Promise<void> {
		if ( this.disabled ) {
			return;
		}

		const directiveParts = directive.split( ':', 2 );
		const action = directiveParts[0]?.trim();
		const target = directiveParts[1]?.trim();

		if ( directiveParts.length === 2 ) {
			this.#fetchActorData( target );
		}

		super.updateState( action );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: '0',
					},
					{
						opacity: '0',
					},
					{
						opacity: '0.2',
					},
					{
						opacity: '1',
					},
				],
				{
					duration: 384,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.modal-dialog__content' ),
				[
					{
						opacity: '1',
					},
					{
						opacity: '0',
					},
				],
				{
					duration: 128,
					easing: 'ease-in-out',
					fill: 'forwards',
				}
			),
		];
	}

	async #fetchActorData( id: string ) {
		this.#actorData = null;
		this.innerHTML = '';

		const content = document.createElement( 'div' );
		content.classList.add( 'modal-dialog__content' );

		this.classList.add( 'ensembles-actor-modal-dialog' );
		this.classList.add( 'modal-dialog' );
		this.setAttribute( 'aria-labelledby', 'ensembles-actor-modal-dialog-label' );
		this.setAttribute( 'aria-modal', 'true' );
		this.setAttribute( 'role', 'dialog' );
		this.setAttribute( 'tabindex', '-1' );
		this.appendChild( content );

		const idempotencyKey = crypto.randomUUID();
		this.#loadingIdempotency = idempotencyKey;

		if ( !id ) {
			return;
		}

		const [
			actorData,
			actorItemsData,
		] = await Promise.all(
			[
				fetchActorData( id ),
				fetchActorItemsData( id ),
			]
		);
		if ( !actorData ) {
			return;
		}

		if ( idempotencyKey !== this.#loadingIdempotency ) {
			console.log( 'this is not the most recent update and render request, discarding the data' );

			return;
		}

		this.#actorData = actorData;
		this.#actorItemsData = actorItemsData;

		this.#loadingIdempotency = '';

		renderActorModalDialog( this, content, this.#actorData, this.#actorItemsData );
	}
}

customElements.define( 'mr-ensembles-actor-modal-dialog', MrEnsemblesActorModalDialog );
