import { EnsemblesEventItemResponse } from '../modules/ensembles/event-items';
import { convertToImgixSrc, imageClamp, mqFromMedium, mqScreen } from '../modules/ensembles/imgix';
import { translate, EN, FR, NL, translateEnsemblesField } from '../modules/ensembles/translation';

export function renderEventModalDialog( dialog: HTMLElement, intoElement: HTMLElement, item: EnsemblesEventItemResponse | null ): void {
	const header = renderHeader( item );
	const image = renderImage( item );
	const description = renderDescription( item );
	const mrInputSink = renderMrInputSink( dialog );

	// structure
	intoElement.classList.add( 'ensembles-event-modal-dialog__content' );
	if ( header ) {
		intoElement.appendChild( header );
	}

	if ( image ) {
		intoElement.appendChild( image );
	}

	if ( description ) {
		intoElement.appendChild( description );
	}

	dialog.classList.add( 'ensembles-event-modal-dialog' );
	dialog.appendChild( mrInputSink );
}

function renderHeader( item: EnsemblesEventItemResponse | null ) {
	if ( !item ) {
		return null;
	}

	const header = document.createElement( 'div' );
	header.classList.add( 'ensembles-event-modal-dialog__header' );

	const title = document.createElement( 'h2' );
	title.classList.add( 'ensembles-event-modal-dialog__title' );
	title.textContent = item.title;
	header.appendChild( title );

	if ( item.actors.length > 0 ) {
		const actors = document.createElement( 'ul' );
		actors.classList.add( 'ensembles-event-modal-dialog__actors' );

		item.actors.forEach( ( actor ) => {
			const actorItem = document.createElement( 'li' );
			actorItem.classList.add( 'ensembles-event-modal-dialog__actor' );

			const actorItemLink = document.createElement( 'a' );
			actorItemLink.classList.add( 'ensembles-event-modal-dialog__actor__link' );
			actorItemLink.href = ( new URL( `./${encodeURIComponent( actor.id )}?locale=${document.documentElement.lang}`, 'https://ensembles.org/actors/' ) ).href;
			actorItemLink.textContent = actor.name;
			actorItemLink.target = '_blank';

			actorItem.appendChild( actorItemLink );
			actors.appendChild( actorItem );
		} );
		header.appendChild( actors );
	}

	if ( item.dimensions ) {
		const dimensions = document.createElement( 'p' );
		dimensions.classList.add( 'ensembles-event-modal-dialog__dimensions' );
		dimensions.textContent = item.dimensions;
		header.appendChild( dimensions );
	}

	if ( item.date_begin ) {
		const dateBegin = document.createElement( 'p' );
		dateBegin.classList.add( 'ensembles-event-modal-dialog__date-begin' );
		dateBegin.textContent = new Date( item.date_begin ).getFullYear().toString();
		header.appendChild( dateBegin );
	}

	if ( item.material ) {
		const materials = document.createElement( 'p' );
		materials.classList.add( 'ensembles-event-modal-dialog__materials' );
		materials.textContent = translate(
			EN.of( 'Materials' ),
			NL.of( 'Materialen' ),
			FR.of( 'Matériaux' )
		) + ': ' + item.material;
		header.appendChild( materials );
	}

	if ( item.collection ) {
		const collection = document.createElement( 'p' );
		collection.classList.add( 'ensembles-event-modal-dialog__collection' );
		collection.textContent = translate(
			EN.of( 'Collection' ),
			NL.of( 'Collectie' ),
			FR.of( 'Collection' )
		) + ': ' + item.collection;
		header.appendChild( collection );
	}

	return header;
}

function renderImage( item: EnsemblesEventItemResponse | null ) {
	if ( !item ) {
		return null;
	}

	const imageContainer = document.createElement( 'div' );
	imageContainer.classList.add( 'ensembles-event-modal-dialog__image-container' );

	if ( item.poster_credits ) {
		const imageCredits = document.createElement( 'p' );
		imageCredits.classList.add( 'ensembles-event-modal-dialog__image-credits' );
		imageCredits.textContent = item.poster_credits;
		imageContainer.appendChild( imageCredits );
	}

	const imageSource = convertToImgixSrc(
		item.poster_image,
		( params ) => {
			params.set( 'w', imageClamp( [
				{
					mq: mqFromMedium,
					min: 300,
					viewportWidthRatio: 0.45,
					max: 700,
				},
				{
					mq: mqScreen,
					min: 300,
					viewportWidthRatio: 0.8,
					max: 1000,
				},
			] ).toString() );
		}
	);

	let image: HTMLElement;
	if ( imageSource ) {
		image = document.createElement( 'img' );
		image.classList.add( 'ensembles-event-modal-dialog__image' );
		image.setAttribute( 'loading', 'lazy' );
		image.setAttribute( 'src', imageSource );
		image.setAttribute( 'alt', '' );
	} else {
		image = document.createElement( 'div' );
		image.classList.add( 'ensembles-event-modal-dialog__image', 'ensembles-event-modal-dialog__image--empty' );
	}

	imageContainer.appendChild( image );

	return imageContainer;
}

function renderDescription( item: EnsemblesEventItemResponse | null ) {
	if ( !item ) {
		return null;
	}

	const description = document.createElement( 'div' );
	description.classList.add( 'ensembles-event-modal-dialog__description' );

	const translation = translateEnsemblesField( item.translations );
	description.innerHTML = translation?.description ?? '';

	return description;
}

function renderMrInputSink( dialog: HTMLElement ) {
	// MrInputSink
	const mrInputSink = document.createElement( 'mr-input-sink' );
	mrInputSink.classList.add( 'ensembles-event-modal-dialog__bg__close' );
	mrInputSink.setAttribute( 'data-directive', 'close' );
	mrInputSink.setAttribute( 'data-for', dialog.id );

	// Button
	const button = document.createElement( 'button' );
	button.textContent = translate(
		EN.of( 'Close' ),
		NL.of( 'Sluit' ),
		FR.of( 'Fermer' )
	);

	mrInputSink.appendChild( button );

	return mrInputSink;
}
