/* eslint-disable no-lone-blocks */

export function renderTeaserY( imgSrc: string | null, titleTextContent: string, actors: string, beginDate: string, itemId: string, modalId: string ): HTMLElement {
	const outerContainer = document.createElement( 'div' );
	outerContainer.classList.add( 'teaser-y' );

	// Container
	const outer = document.createElement( 'div' );
	outer.classList.add( 'teaser-y__item' );

	// Image
	let imageContainer: HTMLElement;
	let img: HTMLElement;
	{
		if ( imgSrc ) {
			imageContainer = document.createElement( 'div' );
			imageContainer.classList.add( 'teaser-y__item__image-container' );

			img = document.createElement( 'img' );
			img.classList.add( 'teaser-y__item__image' );
			img.setAttribute( 'width', '305' );
			img.setAttribute( 'height', '196' );
			img.setAttribute( 'src', imgSrc );
			img.setAttribute( 'alt', '' );

			imageContainer.appendChild( img );
		} else {
			imageContainer = document.createElement( 'div' );
		}
	}

	// Title
	const title = document.createElement( 'p' );
	title.classList.add( 'teaser-y__item__title', 'type-a' );
	title.textContent = titleTextContent;

	// Actors
	const actorsContainer = document.createElement( 'p' );
	actorsContainer.classList.add( 'teaser-y__item__actors', 'type-a' );
	actorsContainer.textContent = actors;

	// Date
	let date: HTMLElement;
	{
		if ( beginDate ) {
			date = document.createElement( 'p' );
			date.classList.add( 'teaser-y__item__date', 'type-a' );
			date.textContent = new Date( beginDate ).getFullYear().toString();
		} else {
			date = document.createElement( 'div' );
		}
	}

	// MrInputSink
	const mrInputSink = document.createElement( 'mr-input-sink' );
	mrInputSink.setAttribute( 'data-for', modalId );
	mrInputSink.setAttribute( 'data-directive', `open:${itemId}` );

	// Button
	const button = document.createElement( 'button' );
	button.classList.add( 'teaser-y__button' );
	button.setAttribute( 'aria-label', 'Open modal' );

	// structure
	{
		outer.appendChild( imageContainer );
		outer.appendChild( title );
		outer.appendChild( actorsContainer );
		outer.appendChild( date );
		mrInputSink.appendChild( button );
		mrInputSink.appendChild( outer );
		outerContainer.appendChild( mrInputSink );
	}

	return outerContainer;
}
