import { EN, FR, NL, translate } from './ensembles/translation';

class MrDeadlineLabel extends HTMLElement {
	connectedCallback() {
		requestAnimationFrame( () => {
			this.render();
		} );
	}

	render() {
		const deadline = this.getAttribute( 'data-deadline' );
		if ( !deadline ) {
			return;
		}

		const deadlineDate = new Date( deadline );
		const today = new Date();
		today.setHours( 0, 0, 0, 0 );

		const days = Math.floor( ( deadlineDate.getTime() - today.getTime() ) / ( 1000 * 3600 * 24 ) );
		let deadlineLabel = this.querySelector( '.deadline-label' );
		if ( !deadlineLabel ) {
			deadlineLabel = document.createElement( 'span' );
			deadlineLabel.classList.add( 'deadline-label' );
			this.appendChild( deadlineLabel );
		}

		if ( days >= -7 && days < 0 ) {
			this.setAttribute( 'data-deadline-passed', '' );

			deadlineLabel.textContent = translate(
				EN.of( 'Just missed' ),
				NL.of( 'Net gemist' ),
				FR.of( 'Juste ratée' )
			);
		} else if ( days >= 0 && days <= 7 ) {
			this.setAttribute( 'data-deadline-soon', '' );

			deadlineLabel.textContent = translate(
				EN.of( 'Last chance' ),
				NL.of( 'Laatste kans' ),
				FR.of( 'Dernière chance' )
			);
		}
	}
}

customElements.define( 'mr-deadline-label', MrDeadlineLabel );
