export async function fetchEventItemData( eventId: string | null ): Promise<EnsemblesEventItemResponse | null> {
	if ( !eventId ) {
		return null;
	}

	try {
		// https://ensembles.org/items/5703.json
		const response = await fetch( `https://ensembles.org/items/${encodeURIComponent( eventId )}.json` );
		if ( !response.ok ) {
			console.warn( 'Could not fetch event data', response );

			return null;
		}

		return await response.json() as EnsemblesEventItemResponse;
	} catch ( e ) {
		console.warn( 'Could not fetch event item data', e );

		return null;
	}
}

export type EnsemblesEventItemResponse = EnsemblesEventItem

export interface EnsemblesEventItem {
	id: number,
	title: string,
	actors: EnsembleEventActors[],
	material: string,
	collection: string,
	translations: EnsembleEventTranslations[],
	date_begin: string,
	date_end: string,
	dimensions: string,
	poster_image: string,
	poster_credits: string,
}

export interface EnsembleEventActors {
	id: number,
	name: string,
	assets: EnsembleEventAssets[]
}

export interface EnsembleEventAssets {
	poster: string,
	poster_credits: string,
}

export interface EnsembleEventTranslations {
	description: string,
	location: string,
	short_description: string,
	locale: string,
}
