import { renderTeaserY } from '../../templates/teaser-y';
import { renderTeaserZ } from '../../templates/teaser-z';
import { EnsemblesItem, EnsemblesActor, EnsemblesEventResponse, fetchEventData } from './event';
import { convertToImgixSrc } from './imgix';
import { translate, EN, FR, NL } from './translation';


/*
	A single "controller" for the entire fetch request.
	Aligning around the fetch call makes it easier to communicate data updates and re-renders.

	- two lists
	- one with actors
	- one with artworks

	- two dialogs
	- one for actor details
	- one for artwork details

	Clicking a list item opens the corresponding dialog.
	On opening the dialog we need to fetch the corresponding data and then render the dialog contents

	We can not give the open state specific urls because this is not a frontend framework.

	We can not do an immediate link out because we don't have the slug in the list response.

	We may need to translate specific bits of data
 */

const NUMBER_OF_ARTWORKS_SHOWN_BY_DEFAULT = 12;

class MrEnsemblesProgramDetailScreen extends HTMLElement {
	#eventData: EnsemblesEventResponse | null = null;

	static get observedAttributes(): Array<string> {
		return [
			'data-event-id',
		];
	}

	get eventId(): string | null {
		return this.getAttribute( 'data-event-id' );
	}

	set eventId( value: string | null ) {
		if ( value ) {
			this.setAttribute( 'data-event-id', value );

			return;
		}

		this.removeAttribute( 'data-event-id' );
	}

	attributeChangedCallback( attrName: string, oldVal: string|null, newVal: string|null ): void {
		if ( 'data-event-id' === attrName ) {
			if ( newVal && !document.querySelector( `mr-ensembles-actor-modal-dialog#ensembles-actor-detail-dialog-${newVal}` ) ) {
				const dialog = document.createElement( 'mr-ensembles-actor-modal-dialog' );
				dialog.id = `ensembles-actor-detail-dialog-${newVal}`;
				document.body.appendChild( dialog );
			}

			if ( newVal && !document.querySelector( `mr-ensembles-event-modal-dialog#ensembles-event-detail-dialog-${newVal}` ) ) {
				const dialog = document.createElement( 'mr-ensembles-event-modal-dialog' );
				dialog.id = `ensembles-event-detail-dialog-${newVal}`;
				document.body.appendChild( dialog );
			}

			fetchEventData( newVal ).then( ( eventData ) => {
				this.#eventData = eventData;
			} ).finally( () => {
				this.#renderEventData();
			} );
		}
	}

	#renderEventData(): void {
		this.innerHTML = '';

		if ( !this.eventId ) {
			return;
		}

		if ( !this.#eventData ) {
			return;
		}

		// ACTOR
		const programDetailListActors = document.createElement( 'div' );
		programDetailListActors.classList.add( 'program-detail__list' );
		programDetailListActors.setAttribute( 'id', 'artists' );

		const participatingArtists = document.createElement( 'ul' );
		participatingArtists.classList.add( 'actors-list' );
		for ( const actor of this.#eventData.actors ) {
			const li = document.createElement( 'li' );
			li.appendChild( actorTeaser( this.eventId, actor ) );
			li.classList.add( 'actors-list__card' );
			participatingArtists.appendChild( li );
		}

		programDetailListActors.appendChild( renderActorTitle() );
		programDetailListActors.appendChild( participatingArtists );

		// ARTWORK
		const programDetailListArtworks = document.createElement( 'div' );
		programDetailListArtworks.classList.add( 'program-detail__list' );
		programDetailListArtworks.setAttribute( 'id', 'artworks' );

		const artworksInExpo = document.createElement( 'div' );
		artworksInExpo.classList.add( 'artworks-list' );

		for ( let index = 0; index < this.#eventData.items.length; index++ ) {
			const element = this.#eventData.items[index];
			const teaser = itemTeaser( this.eventId, element );
			artworksInExpo.appendChild( teaser );
			if ( index >= NUMBER_OF_ARTWORKS_SHOWN_BY_DEFAULT ) {
				teaser.setAttribute( 'hidden', '' );
			}
			if ( index === NUMBER_OF_ARTWORKS_SHOWN_BY_DEFAULT + 1 ) {
				teaser.id = 'show-more';
			}
		}

		programDetailListArtworks.appendChild( renderArtworksTitle() );
		programDetailListArtworks.appendChild( artworksInExpo );

		// SHOW MORE
		if ( this.#eventData.items.length > NUMBER_OF_ARTWORKS_SHOWN_BY_DEFAULT ) {
			programDetailListArtworks.appendChild( renderShowMoreButton( this ) );
		}

		// STRUCTURE
		if ( this.#eventData.actors.length > 0 ) {
			this.appendChild( renderDivider() );
			this.appendChild( programDetailListActors );
		}

		if ( this.#eventData.items.length > 0 ) {
			this.appendChild( renderDivider() );
			this.appendChild( programDetailListArtworks );
		}
	}
}

function renderDivider() {
	const divider = document.createElement( 'hr' );
	divider.classList.add( 'program-detail__divider' );

	return divider;
}

function renderShowMoreButton( parent: MrEnsemblesProgramDetailScreen ) {
	const showMoreContainer = document.createElement( 'div' );
	showMoreContainer.classList.add( 'artworks-list__show-more' );

	const showMoreButton = document.createElement( 'a' );
	showMoreButton.classList.add( 'artworks-list__show-more__button', 'button' );
	showMoreButton.innerHTML = translate(
		EN.of( 'Show more' ),
		NL.of( 'Toon meer' ),
		FR.of( 'Voir plus' )
	);
	showMoreButton.href = '#show-more';

	showMoreButton.addEventListener( 'click', () => {
		const hiddenItems = parent.querySelectorAll( '.artworks-list__item[hidden]' );
		for ( const item of hiddenItems ) {
			item.removeAttribute( 'hidden' );
		}

		showMoreContainer.setAttribute( 'hidden', '' );
	} );

	showMoreContainer.appendChild( showMoreButton );

	return showMoreContainer;
}

function renderActorTitle() {
	const actorTitle = document.createElement( 'h2' );
	actorTitle.classList.add( 'program-detail__list__title' );
	actorTitle.textContent = translate(
		EN.of( 'Participating artists' ),
		NL.of( 'Deelnemende kunstenaars' ),
		FR.of( 'Artistes participants' )
	);

	return actorTitle;
}

function renderArtworksTitle( ) {
	const artworksTitle = document.createElement( 'h2' );
	artworksTitle.classList.add( 'program-detail__list__title' );
	artworksTitle.textContent = translate(
		EN.of( 'Artworks in this expo' ),
		NL.of( 'Kunstwerken in deze expo' ),
		FR.of( 'Œuvres dans cette exposition' )
	);

	return artworksTitle;
}

function actorTeaser( eventId: string, actor: EnsemblesActor ) {
	return renderTeaserZ(
		convertToImgixSrc(
			actor.assets.poster,
			( params ) => {
				params.set( 'w', '384' );
				params.set( 'h', '384' );
				params.set( 'fit', 'crop' );
				params.set( 'crop', 'faces' );
			}
		),
		actor.name,
		actor.id.toString(),
		`ensembles-actor-detail-dialog-${eventId}`
	);
}

function itemTeaser( eventId: string, item: EnsemblesItem ) {
	const teaser = renderTeaserY(
		convertToImgixSrc(
			item.poster_image,
			( params ) => {
				params.set( 'w', '305' );
				params.set( 'h', '196' );
				params.set( 'fit', 'crop' );
				params.set( 'crop', 'faces' );
			}
		),
		item.title,
		item.actors,
		item.date_begin || '',
		item.id.toString(),
		`ensembles-event-detail-dialog-${eventId}`
	);

	teaser.classList.add( 'artworks-list__item' );

	return teaser;
}

customElements.define( 'mr-ensembles-program-detail-screen', MrEnsemblesProgramDetailScreen );
