import '@mrhenry/wp--bugsnag-config';

/* Modules */
import './modules/input-sink';
import './modules/mr-blocked-content';
import './modules/museum-opening-hours';
import './modules/navigation-overlay';
import './modules/deadline-label';

/* Ensembles */
import './modules/ensembles/actor-modal-dialog';
import './modules/ensembles/event-modal-dialog';
import './modules/ensembles/program-detail-screen';

import { PrivacyControls, initMenuButtons } from '@mrhenry/wp--mr-interactive';

PrivacyControls.setupPrivacyAwareEmbedders();

requestAnimationFrame( () => {
	initMenuButtons();
} );
