class MrMuseumOpeningHours extends HTMLElement {
	static #messagePromise: Promise<string|false>|null = null;

	connectedCallback(): void {
		this.getMessage();
	}

	async getMessage() {
		if ( !MrMuseumOpeningHours.#messagePromise ) {
			MrMuseumOpeningHours.#messagePromise = fetchOpeningHoursData();
		}

		const message = await MrMuseumOpeningHours.#messagePromise;
		if ( !message ) {
			return;
		}

		this.innerText = message;
		this.setAttribute( 'loaded', 'true' );
	}
}

customElements.define( 'mr-museum-opening-hours', MrMuseumOpeningHours );

async function fetchOpeningHoursData(): Promise<string|false> {
	try {
		let language = document.documentElement.getAttribute( 'lang' );
		if ( !language ) {
			language = 'en';
		}

		const response = await fetch( `/wp-json/opening-closing-times/general.json?lang=${language}` );
		if ( !response.ok ) {
			let errorMessage = `${response.statusText} ${response.status}`;

			try {
				const body = response.text();
				errorMessage += ( '\n' + body );
			} catch ( err ) {
				console.warn( err );
			}

			throw new Error( errorMessage );
		}

		const jsonResponse = await response.json();
		if ( !jsonResponse || !jsonResponse.message ) {
			return false;
		}

		return jsonResponse.message;
	} catch ( err ) {
		console.warn( err );

		return false;
	}
}
