export async function fetchActorItemsData( actorId: string | null ): Promise<EnsemblesActorItemsResponse | null> {
	if ( !actorId ) {
		return null;
	}

	try {
		// https://ensembles.org/actors/52/items.json
		const response = await fetch( `https://ensembles.org/actors/${encodeURIComponent( actorId )}/items.json` );
		if ( !response.ok ) {
			console.warn( 'Could not fetch event data', response );

			return null;
		}

		return await response.json() as EnsemblesActorItemsResponse;
	} catch ( e ) {
		console.warn( 'Could not fetch event data', e );

		return null;
	}
}

export type EnsemblesActorItemsResponse = Array<EnsemblesActorItem>

export interface EnsemblesActorItem {
	id: number
	title: string
	dimensions: string
	date_begin?: string
	material: string
	short_description: string
	art_status_id: number
	legal_status_id: number
	category_id: number
	platform_id: number
	deleted: boolean
	asset_count: number
	stream_count: number
	collection: string
	cached_tag_list: string
	publishing_process_id: number
	annotation: string
	date_end?: string
	reference: string
	stream_count_app: number
	permalink: string
	description_ru?: string
	description_de?: string
	description_es?: string
	description_el?: string
	short_description_du?: string
	short_description_fr?: string
	short_description_ru?: string
	short_description_de?: string
	short_description_es?: string
	short_description_el?: string
	description_ca?: string
	short_description_ca?: string
	description_it?: string
	short_description_it?: string
	cached_primary_asset_url?: string
	cached_actor_names?: string
	hide_from_json: boolean
	description_uk?: string
	short_description_uk?: string
	poster_image: string
	poster_credits?: string
	translations: EnsemblesTranslation[]
}

export interface EnsemblesTranslation {
	locale: string
	description: string
}
