export function convertToImgixSrc( awsSrc: string | undefined | null, setParams: ( params: URLSearchParams ) => void ): string | null {
	if ( !awsSrc ) {
		return null;
	}

	if ( !awsSrc.startsWith( 'https://s3.amazonaws.com/mhka_ensembles_production/assets/public/' ) ) {
		return awsSrc;
	}

	let imgixSrc = awsSrc.replace( 'https://s3.amazonaws.com/mhka_ensembles_production/assets/public/', 'https://muhka-ensembles.imgix.net/assets/public/' );
	imgixSrc = imgixSrc.split( '?' )[0];

	const imgixUrl = new URL( imgixSrc );
	imgixUrl.searchParams.set( 'dpr', window.devicePixelRatio?.toString() ?? '1' );
	imgixUrl.searchParams.set( 'auto', 'format,compress' );

	setParams( imgixUrl.searchParams );

	return imgixUrl.href;
}

/**
 * Clamp an image width between a minimum and maximum value, sized dynamically based on the viewport width.
 */
export function imageClamp( conditions: Array<{mq: MediaQueryList, min: number, viewportWidthRatio: number, max: number}> ): number {
	const condition = conditions.find( ( x ) => {
		return x.mq.matches;
	} );

	if ( !condition ) {
		return 500;
	}

	return Math.min(
		condition.max,
		Math.max(
			condition.min,
			Math.ceil( ( window.innerWidth / 100 ) * condition.viewportWidthRatio ) * 100
		)
	);
}

export const mqFromX_Small = window.matchMedia( '(min-width: 560px)' );
export const mqFromMedium = window.matchMedia( '(min-width: 1024px)' );
export const mqScreen = window.matchMedia( 'screen' );
