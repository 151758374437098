/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*
*   File:   Menubutton.js
*
*   Desc:   Menubutton widget that implements ARIA Authoring Practices
*/

/*
*   @constructor MenuButton
*
*   @desc
*       Object that configures menu item elements by setting tabIndex
*       and registering itself to handle pertinent events.
*
*       While menuitem elements handle many keydown events, as well as
*       focus and blur events, they do not maintain any state variables,
*       delegating those responsibilities to its associated menu object.
*
*       Consequently, it is only necessary to create one instance of
*       MenubuttonItem from within the menu object; its configure method
*       can then be called on each menuitem element.
*
*   @param domNode
*       The DOM element node that serves as the menu item container.
*       The menuObj PopupMenu is responsible for checking that it has
*       requisite metadata, e.g. role="menuitem".
*
*
*/

import './types.ts';
import { PopupMenu } from './mr-popup-menu';
import { MrHTMLParserSignal } from './parser-signal';

export const Menubutton = function Menubutton( this: MenubuttonType, domNode: HTMLElement ) {

	this.domNode = domNode;
	this.popupMenu = null;

	this.hasFocus = false;
	this.hasHover = false;

	this.keyCode = Object.freeze( {
		TAB: 9,
		RETURN: 13,
		ESC: 27,
		SPACE: 32,
		PAGEUP: 33,
		PAGEDOWN: 34,
		END: 35,
		HOME: 36,
		LEFT: 37,
		UP: 38,
		RIGHT: 39,
		DOWN: 40,
	} );
} as any as { new ( domNode: HTMLElement ): MenubuttonType; }; /* eslint-disable-line @typescript-eslint/no-explicit-any */

Menubutton.prototype.init = function( this: MenubuttonType ) {
	if ( !customElements.get( 'mr-html-parser-signal-for-menu-button' ) ) {
		customElements.define( 'mr-html-parser-signal-for-menu-button', MrHTMLParserSignal );
	}

	const popupMenu = document.getElementById( this.domNode.getAttribute( 'aria-controls' ) || '' );
	if ( !popupMenu ) {
		return;
	}

	const parserSignal = new MrHTMLParserSignal();
	parserSignal.whenConnected = () => {
		this.domNode.setAttribute( 'aria-haspopup', 'true' );

		this.domNode.addEventListener( 'keydown', this.handleKeydown.bind( this ) );
		this.domNode.addEventListener( 'click', this.handleClick.bind( this ) );
		this.domNode.addEventListener( 'focus', this.handleFocus.bind( this ) );
		this.domNode.addEventListener( 'blur', this.handleBlur.bind( this ) );
		this.domNode.addEventListener( 'mouseover', this.handleMouseover.bind( this ) );
		this.domNode.addEventListener( 'mouseout', this.handleMouseout.bind( this ) );

		this.popupMenu = new PopupMenu( popupMenu, this );
		this.popupMenu.init();
	};

	popupMenu.appendChild( parserSignal );
};

Menubutton.prototype.handleKeydown = function( this: MenubuttonType, event: KeyboardEvent ) {
	let flag = false;

	switch ( event.keyCode ) {
		case this.keyCode.SPACE:
		case this.keyCode.RETURN:
		case this.keyCode.DOWN:
			if ( this.popupMenu ) {
				this.popupMenu.open();
				this.popupMenu.setFocusToFirstItem();
			}
			flag = true;
			break;

		case this.keyCode.UP:
			if ( this.popupMenu ) {
				this.popupMenu.open();
				this.popupMenu.setFocusToLastItem();
				flag = true;
			}
			break;

		default:
			break;
	}

	if ( flag ) {
		event.stopPropagation();
		event.preventDefault();
	}
};

Menubutton.prototype.handleClick = function( this: MenubuttonType ) {
	if ( !this.popupMenu ) {
		return;
	}

	if ( 'true' === this.domNode.getAttribute( 'aria-expanded' ) ) {
		this.popupMenu.close( true );
	} else {
		this.popupMenu.open();
		this.popupMenu.setFocusToFirstItem();
	}
};

Menubutton.prototype.handleFocus = function( this: MenubuttonType ) {
	if ( !this.popupMenu ) {
		return;
	}

	this.popupMenu.hasFocus = true;
};

Menubutton.prototype.handleBlur = function( this: MenubuttonType ) {
	if ( !this.popupMenu ) {
		return;
	}

	this.popupMenu.hasFocus = false;
	this.popupMenu.close();
};

Menubutton.prototype.handleMouseover = function( this: MenubuttonType ) {
	if ( !this.popupMenu ) {
		return;
	}

	this.hasHover = true;
};

Menubutton.prototype.handleMouseout = function( this: MenubuttonType ) {
	if ( !this.popupMenu ) {
		return;
	}

	this.hasHover = false;
};
