export class MrHTMLParserSignal extends HTMLElement {
	/*
		Custom Elements, querySelector, ... hook into the opening tag of HTML Elements.
		We want to wait until the closing tag.

		We could use defer when loading the JS bundle, but this delays everything a lot.

		Using a custom element and "connectedCallback" we can construct an event source for the closing tag.

		- new custom element
		- insert it after your target
		- wait for "connectedCallback"
		- you now know that the entire preceding DOM sub tree has been processed.
	*/

	whenConnected = () => {
		/* noop */
	};

	connectedCallback() {
		requestAnimationFrame( () => {
			// Do the callback.
			this.whenConnected();
			// Remove self.
			this.remove();
			// Make the callback a noop.
			this.whenConnected = () => {
				/* noop */
			};
		} );
	}
}
