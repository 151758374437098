/* eslint-disable no-lone-blocks */

import { EnsemblesActorResponse } from '../modules/ensembles/actor';
import { EnsemblesActorItem, EnsemblesActorItemsResponse } from '../modules/ensembles/actor-items';
import { convertToImgixSrc, imageClamp, mqFromMedium, mqFromX_Small, mqScreen } from '../modules/ensembles/imgix';
import { translate, EN, FR, NL, translateEnsemblesField } from '../modules/ensembles/translation';

export function renderActorModalDialog( dialog: HTMLElement, intoElement: HTMLElement, actor: EnsemblesActorResponse, items: EnsemblesActorItemsResponse | null ): void {
	// Anchor links
	const anchorLinks = renderAnchorLinks( actor, items );

	// Info
	const info = renderInfo( actor );

	// Items
	const listItems = renderActorItems( items );

	// More link
	const moreLinkContainer = document.createElement( 'div' );
	moreLinkContainer.classList.add( 'ensembles-actor-modal-dialog__more-link-container' );

	const moreLink = document.createElement( 'a' );
	moreLink.classList.add( 'ensembles-actor-modal-dialog__more-link', 'button' );
	moreLink.href = new URL( `/actors/${encodeURIComponent( actor.permalink )}?locale=${document.documentElement.lang}`, 'https://ensembles.org' ).href;
	moreLink.target = '_blank';

	moreLink.textContent = translate(
		EN.of( 'Discover more on ensembles.org' ),
		NL.of( 'Ontdek meer op ensembles.org' ),
		FR.of( 'Découvrez plus sur ensembles.org' )
	);

	moreLinkContainer.appendChild( moreLink );

	// MrInputSink
	const mrInputSink = document.createElement( 'mr-input-sink' );
	mrInputSink.classList.add( 'ensembles-actor-modal-dialog__bg__close' );
	mrInputSink.setAttribute( 'data-directive', 'close' );
	mrInputSink.setAttribute( 'data-for', dialog.id );

	// Button
	const button = document.createElement( 'button' );
	button.textContent = translate(
		EN.of( 'Close' ),
		NL.of( 'Sluit' ),
		FR.of( 'Fermer' )
	);

	// structure
	{
		// content
		intoElement.appendChild( renderHeader( actor ) );

		if ( anchorLinks ) {
			intoElement.appendChild( anchorLinks );
		}

		if ( info ) {
			intoElement.appendChild( info );
		}

		if ( listItems ) {
			intoElement.appendChild( listItems );
		}

		intoElement.appendChild( moreLinkContainer );

		mrInputSink.appendChild( button );

		intoElement.classList.add( 'ensembles-actor-modal-dialog__content' );

		dialog.classList.add( 'ensembles-actor-modal-dialog' );
		dialog.appendChild( mrInputSink );
	}
}

function renderHeader( actor: EnsemblesActorResponse ) {
	const header = document.createElement( 'div' );
	header.classList.add( 'ensembles-actor-modal-dialog__header' );

	header.appendChild( renderActorNameAndDates( actor ) );
	header.appendChild( renderActorImage( actor ) );

	return header;
}

function renderInfo( actor: EnsemblesActorResponse ): HTMLElement | null {
	const translation = translateEnsemblesField( actor.translations );
	if ( !translation?.description ) {
		return null;
	}

	const info = document.createElement( 'div' );
	info.classList.add( 'ensembles-actor-modal-dialog__info' );
	info.id = 'info';
	info.innerHTML = translation.description;

	return info;
}

function renderAnchorLinks( actor: EnsemblesActorResponse, items: EnsemblesActorItemsResponse | null ): HTMLElement | null {
	const anchorLinks = document.createElement( 'div' );
	anchorLinks.classList.add( 'ensembles-actor-modal-dialog__anchor-links' );

	const translation = translateEnsemblesField( actor.translations );
	if ( translation?.description ) {
		const anchorLinkInfo = document.createElement( 'a' );
		anchorLinkInfo.classList.add( 'ensembles-actor-modal-dialog__anchor-link' );
		anchorLinkInfo.href = '#info';
		anchorLinkInfo.textContent = translate(
			EN.of( 'Info' ),
			NL.of( 'Info' ),
			FR.of( 'Info' )
		);
		anchorLinks.appendChild( anchorLinkInfo );
	}

	if ( items && items.length ) {
		const anchorLinkItems = document.createElement( 'a' );
		anchorLinkItems.classList.add( 'ensembles-actor-modal-dialog__anchor-link' );
		anchorLinkItems.href = '#items';
		anchorLinkItems.textContent = translate(
			EN.of( `Items (${items.length})` ),
			NL.of( `Items (${items.length})` ),
			FR.of( `Œuvres en lien (${items.length})` )
		);
		anchorLinks.appendChild( anchorLinkItems );
	}

	if ( !anchorLinks.children.length ) {
		return null;
	}

	return anchorLinks;
}

function renderActorImage( actor: EnsemblesActorResponse ): HTMLElement {
	const imageContainer = document.createElement( 'div' );
	imageContainer.classList.add( 'ensembles-actor-modal-dialog__header__image-container' );

	const imgSrc = convertToImgixSrc(
		actor.poster_image,
		( params ) => {
			params.set( 'w', imageClamp( [
				{
					mq: mqFromMedium,
					min: 300,
					viewportWidthRatio: 0.45,
					max: 600,
				},
				{
					mq: mqScreen,
					min: 300,
					viewportWidthRatio: 0.8,
					max: 1000,
				},
			] ).toString() );
		}
	);

	if ( imgSrc ) {
		const img = document.createElement( 'img' );
		img.classList.add( 'ensembles-actor-modal-dialog__image' );
		img.src = imgSrc;
		img.setAttribute( 'alt', '' );
		imageContainer.appendChild( img );
	} else {
		const nonImg = document.createElement( 'div' );
		nonImg.classList.add( 'ensembles-actor-modal-dialog__image', 'ensembles-actor-modal-dialog__image--empty' );
		imageContainer.appendChild( nonImg );
	}

	if ( actor.poster_credits ) {
		const posterCredits = document.createElement( 'p' );
		posterCredits.textContent = actor.poster_credits;
		posterCredits.classList.add( 'ensembles-actor-modal-dialog__poster-credits' );
		imageContainer.appendChild( posterCredits );
	}

	return imageContainer;
}

function renderActorNameAndDates( actor: EnsemblesActorResponse ): HTMLElement {
	const actorNameAndDates = document.createElement( 'div' );
	actorNameAndDates.classList.add( 'ensembles-actor-modal-dialog__header__meta' );

	const label = document.createElement( 'h2' );
	label.classList.add( 'ensembles-actor-modal-dialog__label' );
	label.textContent = actor.name ?? '–';
	actorNameAndDates.appendChild( label );

	if ( actor.date_of_birth ) {
		const dateOfBirth = document.createElement( 'span' );
		dateOfBirth.classList.add( 'ensembles-actor-modal-dialog__date-of-birth' );
		dateOfBirth.textContent = new Date( actor.date_of_birth ).getFullYear().toString();
		actorNameAndDates.appendChild( dateOfBirth );
	}

	if ( actor.date_of_death ) {
		const dateOfDeath = document.createElement( 'span' );
		dateOfDeath.classList.add( 'ensembles-actor-modal-dialog__date-of-death' );
		dateOfDeath.textContent = new Date( actor.date_of_death ).getFullYear().toString();
		actorNameAndDates.appendChild( dateOfDeath );
	}

	return actorNameAndDates;
}

function renderActorItems( items: EnsemblesActorItemsResponse | null ): HTMLElement | null {
	if ( !items || !items.length ) {
		return null;
	}

	const listItems = document.createElement( 'ul' );
	listItems.classList.add( 'ensembles-actor-modal-dialog__list' );
	listItems.id = 'items';

	for ( const item of items ) {
		listItems.appendChild( renderActorItem( item ) );
	}

	return listItems;
}

function renderActorItem( item: EnsemblesActorItem ): HTMLElement {
	const listItem = document.createElement( 'li' );
	listItem.classList.add( 'ensembles-actor-modal-dialog__list__item' );

	const listLink = document.createElement( 'a' );
	listLink.classList.add( 'ensembles-actor-modal-dialog__list__link' );
	listLink.href = ( new URL( `./${encodeURIComponent( item.id )}?locale=${document.documentElement.lang}`, 'https://ensembles.org/items/' ) ).href;
	listLink.target = '_blank';

	const linkDateBegin = document.createElement( 'span' );
	linkDateBegin.classList.add( 'ensembles-actor-modal-dialog__list__link__date-begin' );
	if ( item.date_begin ) {
		linkDateBegin.textContent = new Date( item.date_begin ).getFullYear().toString();
	}

	const listImage = document.createElement( 'img' );

	const imgSrc = convertToImgixSrc(
		item.poster_image,
		( params ) => {
			params.set( 'fit', 'crop' );
			params.set( 'crop', 'faces' );
			params.set( 'ar', '1:1' );

			params.set( 'w', imageClamp( [
				{
					mq: mqFromMedium,
					min: 250,
					viewportWidthRatio: 0.25,
					max: 250,
				},
				{
					mq: mqFromX_Small,
					min: 250,
					viewportWidthRatio: 0.45,
					max: 450,
				},
				{
					mq: mqScreen,
					min: 300,
					viewportWidthRatio: 0.8,
					max: 500,
				},
			] ).toString() );
		}
	);

	listImage.setAttribute( 'loading', 'lazy' );
	listImage.setAttribute( 'width', '250' );
	listImage.setAttribute( 'height', '250' );
	listImage.src = imgSrc ?? '';

	const listTitle = document.createElement( 'div' );
	listTitle.classList.add( 'ensembles-actor-modal-dialog__list__title' );
	listTitle.textContent = item.title;

	const listContent = document.createElement( 'div' );
	listContent.classList.add( 'ensembles-actor-modal-dialog__list__content' );

	listContent.appendChild( listTitle );
	listContent.appendChild( linkDateBegin );

	listLink.appendChild( listImage );
	listLink.appendChild( listContent );

	listItem.appendChild( listLink );

	return listItem;
}
