export async function fetchEventData( eventId: string | null ): Promise<EnsemblesEventResponse | null> {
	if ( !eventId ) {
		return null;
	}

	try {
		// https://ensembles.org/events/14708.json
		const response = await fetch( `https://ensembles.org/events/${encodeURIComponent( eventId )}.json` );
		if ( !response.ok ) {
			console.warn( 'Could not fetch event data', response );

			return null;
		}

		return await response.json() as EnsemblesEventResponse;
	} catch ( e ) {
		console.warn( 'Could not fetch event data', e );

		return null;
	}
}

export interface EnsemblesEventResponse {
	id: number
	title: string
	date_begin?: string
	date_end?: string
	location: Location
	assets?: EnsemblesAssets
	translations: EnsemblesTranslation[]
	actors: EnsemblesActor[]
	items: EnsemblesItem[]
}

export interface EnsemblesLocation {
	readable: string
}

export interface EnsemblesAssets {
	poster?: string
	poster_credits?: string
}

export interface EnsemblesTranslation {
	locale: string
	name?: string
	short_description: string
	description: string
}

export interface EnsemblesActor {
	id: number
	name: string
	assets: EnsemblesAssets
}

export interface EnsemblesItem {
	id: number
	title: string
	date_begin?: string
	poster_image?: string
	poster_credits?: string
	actors: string
}
